import request from '@/service';

// 获取下拉类型字典项
export async function getTypeSelect(params) {
  return request('/teacher-service/dict/get_dict_item_list', {
    method: 'get',
    data: params,
  });
}

// -----------------------------

// 获取课详情
export async function getCourseDetail(type, params) {
  if (type === 'ONE_TO_ONE') {
    return request('/teacher-service/class_scheduled/detail_for_1v1', {
      method: 'get',
      data: params,
    });
  } else if (type === 'TEAM_CLASS') {
    return request('/teacher-service/class_scheduled/detail_for_team', {
      method: 'get',
      data: params,
    });
  } else if (type === 'OPEN_CLASS') {
    return request('/teacher-service/class_scheduled/detail_for_open', {
      method: 'get',
      data: params,
    });
  }
}

// 1v1 获取学员信息
export async function getStudentInfo(params) {
  return request('/teacher-service/class_scheduled/get_student_info_in_1v1_class_detail', {
    method: 'get',
    data: params,
  });
}

// 评价
export async function evaluateStudent(params) {
  return request('/teacher-service/class_scheduled/evaluation_class', {
    method: 'post',
    data: params,
  });
}

// 获取评价
export async function getEvaluation(params) {
  return request('/teacher-service/class_scheduled/get_evaluation', {
    method: 'get',
    data: params,
  });
}

// 获取进入教室 url
export async function getEnterUrl(params) {
  return request('/teacher-service/online_room/get_enter_url', {
    method: 'get',
    data: params,
  });
}

// 获取回放url
export async function getPlaybackUrl(params) {
  return request('/teacher-service/online_room/get_playback_url', {
    method: 'get',
    data: params,
  });
}

// 公开课取消课（学生）
export async function cancelClassForStudent(type, params) {
  if (type === 'TEAM_CLASS') {
    return request('/curriculum-service/class_schedule/team/cancel_student_class', {
      method: 'post',
      data: params,
    });
  } else if (type === 'OPEN_CLASS') {
    return request('/curriculum-service/class_schedule/open_class/cancel_student_class', {
      method: 'post',
      data: params,
    });
  }
}

// 获取录播视频数据
export async function getRecordVideoData(params) {
  return request('/teacher-service/online_room/get_record_video_data', {
    method: 'get',
    data: params,
  });
}

// 获取在线教室数据
export async function getOnlineRoomData(params) {
  return request('/teacher-service/online_room/get_online_room_data', {
    method: 'get',
    data: params,
  });
}

// 获取播放地址
export async function getPreviewUrl(params) {
  return request('/teacher-service/online_room/get_record_video_play_url', {
    method: 'get',
    data: params,
  });
}
